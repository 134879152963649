import logo from './logo.svg';
import './App.css';
import DataTable from './components/DataTable';
import Example from './components/Example';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import DashboardComponent from './components/DashboardComponent';
import Drogalogin from './components/Drogalogin';
import Navbarr from './components/Navbarr';
import ReportTable from'./components/ReportTable';
import Accountreport from'./components/Accountreport';
import Accounttable from'./components/Accounttable';
import SentReportTable from './components/SentReportTable';
import MediaLinksCard from './components/MediaLinksCard';
import RegisterForm from './components/RegisterForm';
import ChangePasswordComponent from './components/ChangePasswordComponent';
import Botchatdroga from './components/Botchatdroga';
import Dashboard from './components/Dashboard';
import BasicStacking from './components/BasicStacking';
import PieAnimation from './components/PieAnimation';
import MediaLinks from './components/media';
import testpdj from './components/testpdj';
import local_and_international from './components/local_and_international';
import ReportTableNew from './components/ReportTableNew';
import TwitterReportTable from './components/TwitterReportTable';
import FacebookReportTable from './components/FacebookReportTable';
import TikTokReportTable from './components/TikTokReportTable';
import PerformanceTable from './components/PerformanceTable';
import eyu from './components/eyu';
import EmployeeData from './components/EmployeeData';
import PerformanceReportTable from './components/PerformanceReportTable';
import PerformanceReportForm from './components/PerformanceReportForm';
import ReportList from './components/ReportList';
import WeeklyReportWithFetch from './components/WeeklyReportWithFetch';
import SummaryReportGraph from './components/SummaryReportGraph';
import WeeklyReportTable from './components/WeeklyReportTable';
import WeeklyReportForm from './components/WeeklyReportForm';
import ReportSystem from './components/ReportSystem';
import AssessmentTable from './components/AssessmentTable';
function App() {
  return (
<div className="App">
      <BrowserRouter>

        <Routes >
        <Route path='/Example' element={<> <Example /></>} />
        <Route path='/DataTable' element={<> <Navbarr /> <DataTable /></>} />
        <Route path='/' element={<><DashboardComponent /></>} />
        <Route path='/login' element={<> <Drogalogin /></>} />
        <Route path='/Navbar' element={<> <Navbarr /></>} />
        <Route path='/ReportTable' element={<> <Navbarr /><ReportTable /></>} />
        <Route path='/Accountreport' element={<> <Navbarr /><Accountreport /></>} />
        <Route path='/Accounttable' element={<> <Navbarr /><Accounttable /></>} />
        <Route path='/SentReportTable' element={<> <SentReportTable /></>} />
        <Route path='/MediaLinksCard' element={<> <Botchatdroga /><Navbarr /> <MediaLinksCard /></>} />
        <Route path='/RegisterForm' element={<> <Navbarr /> <RegisterForm /><Botchatdroga /></>} />
        <Route path='/ChangePassword' element={<> <Navbarr /> <ChangePasswordComponent /></>} />
        <Route path='/Dashboard' element={<> <Navbarr /> <Dashboard /></>} />
        <Route path='/BasicStacking' element={<> <Navbarr /> <BasicStacking /></>} />
        <Route path='/PieAnimation' element={<> <Navbarr /> <PieAnimation /></>} />
        <Route path='/MediaLinks' element={<> <Navbarr /> <MediaLinks /></>} />
        <Route path='/testpdj' element={<> <Navbarr /> <testpdj /></>} />
        <Route path='/local_and_international' element={<> <Navbarr /> <local_and_international /></>} />
        <Route path='/Contentreport' element={<> <Navbarr /> <ReportTableNew /></>} />
        <Route path='/TwitterReportTable' element={<> <Navbarr /> <TwitterReportTable /></>} />
        <Route path='/FacebookReportTable' element={<> <Navbarr /> <FacebookReportTable /></>} /> 
        <Route path='/TikTokReportTable' element={<> <Navbarr /> <TikTokReportTable /></>} />
        <Route path='/PerformanceTable' element={<><PerformanceTable /></>} />
        <Route path='/eyu' element={<> <eyu /></>} />
        <Route path='/EmployeeData' element={<> <EmployeeData /></>} />
        <Route path='/PerformanceReportTable' element={<><Navbarr /> <PerformanceReportTable /></>} />
        <Route path='/PerformanceReportForm' element={<><Navbarr /> <PerformanceReportForm /></>} />
        <Route path='/ReportList' element={<><Navbarr /> <ReportList /></>} />
        <Route path='/WeeklyReportWithFetch' element={<><WeeklyReportWithFetch /></>} />
        <Route path='/SummaryReportGraph' element={<><Navbarr /> <SummaryReportGraph /></>} />
        <Route path='/WeeklyReportTable' element={<><Navbarr /> <WeeklyReportTable  /></>} />
        <Route path='/WeeklyReportForm' element={<><Navbarr /> <WeeklyReportForm /></>} />
        <Route path='/ReportSystem' element={<><ReportSystem /></>} />
        <Route path='/AssessmentTable' element={<><AssessmentTable /></>} />

        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;














