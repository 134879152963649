// // // // // // // import React from 'react';
// // // // // // // import ppFrontImage from './ppfront.jpg';
// // // // // // // function ReportSystem() {
// // // // // // //   return (
// // // // // // //     <div className="min-h-screen bg-gray-100 p-8">
// // // // // // //       {/* Header Section with Image */}
// // // // // // //       <div className="relative overflow-hidden rounded-lg shadow-lg mb-8">
// // // // // // //         <img
// // // // // // //           src={ppFrontImage}
// // // // // // //           alt="Government Report"
// // // // // // //           className="w-full h-80 object-cover"
// // // // // // //         />
// // // // // // //         <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
// // // // // // //           <h1 className="text-4xl font-bold text-white text-center">Government Report System</h1>
// // // // // // //         </div>
// // // // // // //       </div>

// // // // // // //       {/* Action Button */}
// // // // // // //       <div className="flex justify-center mb-8">
// // // // // // //         <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-lg">
// // // // // // //           Generate Report
// // // // // // //         </button>
// // // // // // //       </div>

// // // // // // //       {/* Table Section */}
// // // // // // //       <div className="bg-white rounded-lg shadow-lg p-4">
// // // // // // //         <h2 className="text-xl font-semibold mb-4 text-gray-800">Report Data</h2>
// // // // // // //         <div className="overflow-x-auto">
// // // // // // //           <table className="min-w-full bg-white border border-gray-200">
// // // // // // //             <thead>
// // // // // // //               <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
// // // // // // //                 <th className="py-3 px-6 text-left">Column 1</th>
// // // // // // //                 <th className="py-3 px-6 text-left">Column 2</th>
// // // // // // //                 <th className="py-3 px-6 text-left">Column 3</th>
// // // // // // //                 <th className="py-3 px-6 text-left">Column 4</th>
// // // // // // //               </tr>
// // // // // // //             </thead>
// // // // // // //             <tbody className="text-gray-700 text-sm font-light">
// // // // // // //               {/* Sample Rows */}
// // // // // // //               <tr className="border-b border-gray-200 hover:bg-gray-100">
// // // // // // //                 <td className="py-3 px-6 text-left">Data 1</td>
// // // // // // //                 <td className="py-3 px-6 text-left">Data 2</td>
// // // // // // //                 <td className="py-3 px-6 text-left">Data 3</td>
// // // // // // //                 <td className="py-3 px-6 text-left">Data 4</td>
// // // // // // //               </tr>
// // // // // // //               <tr className="border-b border-gray-200 hover:bg-gray-100">
// // // // // // //                 <td className="py-3 px-6 text-left">Data 5</td>
// // // // // // //                 <td className="py-3 px-6 text-left">Data 6</td>
// // // // // // //                 <td className="py-3 px-6 text-left">Data 7</td>
// // // // // // //                 <td className="py-3 px-6 text-left">Data 8</td>
// // // // // // //               </tr>
// // // // // // //               {/* Add more rows as needed */}
// // // // // // //             </tbody>
// // // // // // //           </table>
// // // // // // //         </div>
// // // // // // //       </div>
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // }

// // // // // // // export default ReportSystem;


// // // // // // import React from 'react';
// // // // // // import ppFrontImage from './ppfront.jpg'; // Adjust the path if needed

// // // // // // function ReportSystem() {
// // // // // //   return (
// // // // // //     <div className="min-h-screen bg-gray-50 p-8">
// // // // // //       {/* Header Section with Animated Image */}
// // // // // //       <div className="relative overflow-hidden rounded-lg shadow-lg mb-8 animate__animated animate__fadeIn">
// // // // // //         <img
// // // // // //           src={ppFrontImage}
// // // // // //           alt="Government Report"
// // // // // //           className="w-full h-80 object-cover transform transition duration-700 hover:scale-105"
// // // // // //         />
// // // // // //         <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
// // // // // //           <h1 className="text-4xl font-bold text-white text-center">Government Report System</h1>
// // // // // //         </div>
// // // // // //       </div>

// // // // // //       {/* Action Button */}
// // // // // //       <div className="flex justify-center mb-8">
// // // // // //         <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-6 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
// // // // // //           Generate Report
// // // // // //         </button>
// // // // // //       </div>

// // // // // //       {/* Table Section with Custom Colors */}
// // // // // //       <div className="bg-white rounded-lg shadow-lg p-4">
// // // // // //         <h2 className="text-xl font-semibold mb-4 text-gray-800">Report Data</h2>
// // // // // //         <div className="overflow-x-auto">
// // // // // //           <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
// // // // // //             <thead>
// // // // // //               <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
// // // // // //                 <th className="py-3 px-6 text-left">Column 1</th>
// // // // // //                 <th className="py-3 px-6 text-left">Column 2</th>
// // // // // //                 <th className="py-3 px-6 text-left">Column 3</th>
// // // // // //                 <th className="py-3 px-6 text-left">Column 4</th>
// // // // // //               </tr>
// // // // // //             </thead>
// // // // // //             <tbody className="text-gray-700 text-sm font-light">
// // // // // //               {/* Sample Rows */}
// // // // // //               <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // // // // //                 <td className="py-3 px-6 text-left">Data 1</td>
// // // // // //                 <td className="py-3 px-6 text-left">Data 2</td>
// // // // // //                 <td className="py-3 px-6 text-left">Data 3</td>
// // // // // //                 <td className="py-3 px-6 text-left">Data 4</td>
// // // // // //               </tr>
// // // // // //               <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // // // // //                 <td className="py-3 px-6 text-left">Data 5</td>
// // // // // //                 <td className="py-3 px-6 text-left">Data 6</td>
// // // // // //                 <td className="py-3 px-6 text-left">Data 7</td>
// // // // // //                 <td className="py-3 px-6 text-left">Data 8</td>
// // // // // //               </tr>
// // // // // //               {/* Add more rows as needed */}
// // // // // //             </tbody>
// // // // // //           </table>
// // // // // //         </div>
// // // // // //       </div>
// // // // // //     </div>
// // // // // //   );
// // // // // // }

// // // // // // export default ReportSystem;



// // // // // import React from 'react';
// // // // // import ppFrontImage from './ppfront.jpg'; // Ensure the path to your image is correct

// // // // // function ReportSystem() {
// // // // //   return (
// // // // //     <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-1">
// // // // //       {/* Glass Effect Background */}
// // // // //       <div className="w-full max-w-screen-lg backdrop-blur-lg bg-white bg-opacity-20 rounded-xl shadow-lg p-6">
        
// // // // //         {/* Header Section with Animated Image */}
// // // // //         <div className="relative overflow-hidden rounded-lg shadow-xl mb-8 transition-transform duration-700 hover:scale-105 hover:rotate-1">
// // // // //           <img
// // // // //             src={ppFrontImage}
// // // // //             alt="Government Report"
// // // // //             className="w-full h-[20rem] object-cover transition-all duration-500 ease-in-out transform hover:scale-110"
// // // // //           />
// // // // //           <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
// // // // //             <h1 className="text-5xl font-bold text-white text-center">Government Report System</h1>
// // // // //           </div>
// // // // //         </div>

// // // // //         {/* Action Button */}
// // // // //         <div className="flex justify-center mb-8">
// // // // //           <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-8 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
// // // // //             Generate Report
// // // // //           </button>
// // // // //         </div>

// // // // //         {/* Table Section with Custom Colors */}
// // // // //         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6">
// // // // //           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Report Data</h2>
// // // // //           <div className="overflow-x-auto">
// // // // //             <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
// // // // //               <thead>
// // // // //                 <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
// // // // //                   <th className="py-3 px-6 text-left">Column 1</th>
// // // // //                   <th className="py-3 px-6 text-left">Column 2</th>
// // // // //                   <th className="py-3 px-6 text-left">Column 3</th>
// // // // //                   <th className="py-3 px-6 text-left">Column 4</th>
// // // // //                 </tr>
// // // // //               </thead>
// // // // //               <tbody className="text-gray-700 text-sm font-light">
// // // // //                 {/* Sample Rows */}
// // // // //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // // // //                   <td className="py-3 px-6 text-left">Data 1</td>
// // // // //                   <td className="py-3 px-6 text-left">Data 2</td>
// // // // //                   <td className="py-3 px-6 text-left">Data 3</td>
// // // // //                   <td className="py-3 px-6 text-left">Data 4</td>
// // // // //                 </tr>
// // // // //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // // // //                   <td className="py-3 px-6 text-left">Data 5</td>
// // // // //                   <td className="py-3 px-6 text-left">Data 6</td>
// // // // //                   <td className="py-3 px-6 text-left">Data 7</td>
// // // // //                   <td className="py-3 px-6 text-left">Data 8</td>
// // // // //                 </tr>
// // // // //                 {/* Add more rows as needed */}
// // // // //               </tbody>
// // // // //             </table>
// // // // //           </div>
// // // // //         </div>
// // // // //       </div>
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default ReportSystem;



// // // // import React from 'react';
// // // // import ppFrontImage from './ppfront.jpg'; // Ensure the path to your image is correct

// // // // function ReportSystem() {
// // // //   return (
// // // //     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-8">
// // // //       {/* Glass Effect Background */}
// // // //       <div className="w-full backdrop-blur-lg bg-white bg-opacity-20 rounded-xl shadow-lg p-6">
        
// // // //         {/* Header Section with Animated Image */}
// // // //         <div className="relative overflow-hidden rounded-lg shadow-xl mb-8 transition-transform duration-700 hover:scale-105 hover:rotate-1">
// // // //           <img
// // // //             src={ppFrontImage}
// // // //             alt="Government Report"
// // // //             className="w-full h-[20rem] object-cover transition-all duration-500 ease-in-out transform hover:scale-110"
// // // //           />
// // // //           <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
// // // //             <h1 className="text-5xl font-bold text-white text-center">Government Report System</h1>
// // // //           </div>
// // // //         </div>

// // // //         {/* Action Button */}
// // // //         <div className="flex justify-center mb-8">
// // // //           <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-8 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
// // // //             Generate Report
// // // //           </button>
// // // //         </div>

// // // //         {/* Table Section with Custom Colors */}
// // // //         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6">
// // // //           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Report Data</h2>
// // // //           <div className="overflow-x-auto">
// // // //             <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
// // // //               <thead>
// // // //                 <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
// // // //                   <th className="py-3 px-6 text-left">Column 1</th>
// // // //                   <th className="py-3 px-6 text-left">Column 2</th>
// // // //                   <th className="py-3 px-6 text-left">Column 3</th>
// // // //                   <th className="py-3 px-6 text-left">Column 4</th>
// // // //                 </tr>
// // // //               </thead>
// // // //               <tbody className="text-gray-700 text-sm font-light">
// // // //                 {/* Sample Rows */}
// // // //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // // //                   <td className="py-3 px-6 text-left">Data 1</td>
// // // //                   <td className="py-3 px-6 text-left">Data 2</td>
// // // //                   <td className="py-3 px-6 text-left">Data 3</td>
// // // //                   <td className="py-3 px-6 text-left">Data 4</td>
// // // //                 </tr>
// // // //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // // //                   <td className="py-3 px-6 text-left">Data 5</td>
// // // //                   <td className="py-3 px-6 text-left">Data 6</td>
// // // //                   <td className="py-3 px-6 text-left">Data 7</td>
// // // //                   <td className="py-3 px-6 text-left">Data 8</td>
// // // //                 </tr>
// // // //                 {/* Add more rows as needed */}
// // // //               </tbody>
// // // //             </table>
// // // //           </div>
// // // //         </div>
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default ReportSystem;


// // // import React from 'react';
// // // import ppFrontImage from './ppfront.jpg'; // Ensure the path to your image is correct

// // // function ReportSystem() {
// // //   return (
// // //     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-2">
// // //       {/* Glass Effect Full Width Card */}
// // //       <div className="w-full backdrop-blur-xl bg-white bg-opacity-30 rounded-xl shadow-lg p-8 space-y-16">
        
// // //         {/* Spaced Header Section with Animated Image */}
// // //         <div className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-700 hover:scale-105">
// // //           <img
// // //             src={ppFrontImage}
// // //             alt="Government Report"
// // //             className="w-full h-[24rem] object-cover transition-all duration-500 ease-in-out transform hover:scale-110"
// // //           />
// // //           <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
// // //             <h1 className="text-5xl font-bold text-white text-center">Government Report System</h1>
// // //           </div>
// // //         </div>

// // //         {/* Action Button */}
// // //         <div className="flex justify-center">
// // //           <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-8 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
// // //             Generate Report
// // //           </button>
// // //         </div>

// // //         {/* Table Section with Custom Colors and Effects */}
// // //         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6">
// // //           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Report Data</h2>
// // //           <div className="overflow-x-auto">
// // //             <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
// // //               <thead>
// // //                 <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
// // //                   <th className="py-3 px-6 text-left">Column 1</th>
// // //                   <th className="py-3 px-6 text-left">Column 2</th>
// // //                   <th className="py-3 px-6 text-left">Column 3</th>
// // //                   <th className="py-3 px-6 text-left">Column 4</th>
// // //                 </tr>
// // //               </thead>
// // //               <tbody className="text-gray-700 text-sm font-light">
// // //                 {/* Sample Rows */}
// // //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // //                   <td className="py-3 px-6 text-left">Data 1</td>
// // //                   <td className="py-3 px-6 text-left">Data 2</td>
// // //                   <td className="py-3 px-6 text-left">Data 3</td>
// // //                   <td className="py-3 px-6 text-left">Data 4</td>
// // //                 </tr>
// // //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// // //                   <td className="py-3 px-6 text-left">Data 5</td>
// // //                   <td className="py-3 px-6 text-left">Data 6</td>
// // //                   <td className="py-3 px-6 text-left">Data 7</td>
// // //                   <td className="py-3 px-6 text-left">Data 8</td>
// // //                 </tr>
// // //                 {/* Add more rows as needed */}
// // //               </tbody>
// // //             </table>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // }

// // // export default ReportSystem;


// // import React, { useState, useEffect } from 'react';
// // import ppFrontImage1 from './ppfront.jpg'; // Ensure the path to your images is correct
// // import ppFrontImage2 from './ppfront.jpg';
// // import ppFrontImage3 from './ppfront.jpg';

// // const images = [ppFrontImage1, ppFrontImage2, ppFrontImage3];

// // function ReportSystem() {
// //   const [currentImageIndex, setCurrentImageIndex] = useState(0);

// //   // Cycle through images
// //   useEffect(() => {
// //     const intervalId = setInterval(() => {
// //       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
// //     }, 3000); // Change image every 3 seconds

// //     return () => clearInterval(intervalId); // Clear interval on component unmount
// //   }, []);

// //   return (
// //     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-2">
// //       <style>
// //         {`
// //           @keyframes marquee {
// //             0% { transform: translateX(100%); }
// //             100% { transform: translateX(-100%); }
// //           }

// //           .animate-marquee {
// //             animation: marquee 15s linear infinite;
// //           }
// //         `}
// //       </style>

// //       {/* Glass Effect Full Width Card */}
// //       <div className="w-full backdrop-blur-xl bg-white bg-opacity-30 rounded-xl shadow-lg p-8 space-y-16">
        
// //         {/* Image Carousel Section */}
// //         <div className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-700 hover:scale-105">
// //           <img
// //             src={images[currentImageIndex]}
// //             alt="Government Report"
// //             className="w-full h-[24rem] object-cover transition-all duration-500 ease-in-out transform hover:scale-110"
// //           />
// //           <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
// //             <h1 className="text-5xl font-bold text-white text-center">Government Report System</h1>
// //           </div>
// //           {/* Moving Text at Bottom of the Image */}
// //           <div className="absolute bottom-0 w-full bg-blue-800 bg-opacity-70 text-white py-2 text-center text-lg animate-marquee">
// //             Welcome to the Government Report System - Secure and Efficient
// //           </div>
// //         </div>

// //         {/* Action Button */}
// //         <div className="flex justify-center">
// //           <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-8 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
// //             Generate Report
// //           </button>
// //         </div>

// //         {/* Table Section with Custom Colors and Effects */}
// //         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6">
// //           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Report Data</h2>
// //           <div className="overflow-x-auto">
// //             <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
// //               <thead>
// //                 <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
// //                   <th className="py-3 px-6 text-left">Column 1</th>
// //                   <th className="py-3 px-6 text-left">Column 2</th>
// //                   <th className="py-3 px-6 text-left">Column 3</th>
// //                   <th className="py-3 px-6 text-left">Column 4</th>
// //                 </tr>
// //               </thead>
// //               <tbody className="text-gray-700 text-sm font-light">
// //                 {/* Sample Rows */}
// //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// //                   <td className="py-3 px-6 text-left">Data 1</td>
// //                   <td className="py-3 px-6 text-left">Data 2</td>
// //                   <td className="py-3 px-6 text-left">Data 3</td>
// //                   <td className="py-3 px-6 text-left">Data 4</td>
// //                 </tr>
// //                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
// //                   <td className="py-3 px-6 text-left">Data 5</td>
// //                   <td className="py-3 px-6 text-left">Data 6</td>
// //                   <td className="py-3 px-6 text-left">Data 7</td>
// //                   <td className="py-3 px-6 text-left">Data 8</td>
// //                 </tr>
// //                 {/* Add more rows as needed */}
// //               </tbody>
// //             </table>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default ReportSystem;




// import React, { useState, useEffect } from 'react';
// import ppFrontImage1 from './ppfront.jpg'; // Ensure the path to your images is correct
// import ppFrontImage2 from './ppfront.jpg';
// import ppFrontImage3 from './ppfront.jpg';

// const images = [ppFrontImage1, ppFrontImage2, ppFrontImage3];

// function ReportSystem() {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   // Cycle through images
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change image every 3 seconds

//     return () => clearInterval(intervalId); // Clear interval on component unmount
//   }, []);

//   return (
//     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-2">
//       <style>
//         {`
//           @keyframes marquee {
//             0% { transform: translateX(100%); }
//             50% { transform: translateX(-100%); }
//             100% { transform: translateX(-100%); }
//           }

//           .animate-marquee {
//             animation: marquee 10s linear infinite;
//             white-space: nowrap;
//           }
//         `}
//       </style>

//       {/* Glass Effect Full Width Card */}
//       <div className="w-full backdrop-blur-xl bg-white bg-opacity-30 rounded-xl shadow-lg p-8 space-y-16">
        
//         {/* Image Carousel Section */}
//         <div className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-700 hover:scale-105">
//           <img
//             src={images[currentImageIndex]}
//             alt="Government Report"
//             className="w-full h-[24rem] object-cover transition-all duration-500 ease-in-out transform hover:scale-110"
//           />
//           <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
//             <h1 className="text-5xl font-bold text-white text-center">Government Report System</h1>
//           </div>

//           {/* Moving Text at Bottom of the Image */}
//           <div className="absolute bottom-0 w-full bg-blue-800 bg-opacity-70 text-white py-2 overflow-hidden">
//             <div className="animate-marquee inline-block px-4">
//               Welcome to the Government Report System - Secure and Efficient
//             </div>
//           </div>
//         </div>

//         {/* Action Button */}
//         <div className="flex justify-center">
//           <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-8 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
//             Generate Report
//           </button>
//         </div>

//         {/* Table Section with Custom Colors and Effects */}
//         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6">
//           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Report Data</h2>
//           <div className="overflow-x-auto">
//             <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
//               <thead>
//                 <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
//                   <th className="py-3 px-6 text-left">Column 1</th>
//                   <th className="py-3 px-6 text-left">Column 2</th>
//                   <th className="py-3 px-6 text-left">Column 3</th>
//                   <th className="py-3 px-6 text-left">Column 4</th>
//                 </tr>
//               </thead>
//               <tbody className="text-gray-700 text-sm font-light">
//                 {/* Sample Rows */}
//                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
//                   <td className="py-3 px-6 text-left">Data 1</td>
//                   <td className="py-3 px-6 text-left">Data 2</td>
//                   <td className="py-3 px-6 text-left">Data 3</td>
//                   <td className="py-3 px-6 text-left">Data 4</td>
//                 </tr>
//                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
//                   <td className="py-3 px-6 text-left">Data 5</td>
//                   <td className="py-3 px-6 text-left">Data 6</td>
//                   <td className="py-3 px-6 text-left">Data 7</td>
//                   <td className="py-3 px-6 text-left">Data 8</td>
//                 </tr>
//                 {/* Add more rows as needed */}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ReportSystem;




// import React from 'react';
// import ppFrontImage from './ppfront.jpg'; // Ensure the path to your image is correct
// import SummaryReportGraph from './SummaryReportGraph';

// function ReportSystem() {
//   return (
//     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-2">
//       {/* Glass Effect Full Width Card */}
//       <div className="w-full backdrop-blur-xl bg-white bg-opacity-30 rounded-xl shadow-lg p-8 space-y-16">
        
//         {/* Spaced Header Section with Animated Image */}
//         <div className="relative overflow-hidden rounded-lg shadow-lg">
//           <img
//             src={ppFrontImage}
//             alt="Government Report"
//             className="w-full h-[24rem] object-cover"
//           />
//           <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
//             <h1 className="text-5xl font-bold text-white text-center">Government Report System</h1>
//           </div>
//         </div>

//         {/* Action Button */}
//         <div className="flex justify-center">
//           <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-8 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
//             Generate Report
//           </button>
//         </div>

//         {/* Scrollable Graph Section */}
//         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6 max-h-96 overflow-y-scroll">
//           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Summary Report Graphs</h2>
//           <SummaryReportGraph />
//         </div>
        
//         {/* Table Section with Custom Colors and Effects */}
//         <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-6">
//           <h2 className="text-2xl font-semibold mb-4 text-gray-800">Report Data</h2>
//           <div className="overflow-x-auto">
//             <table className="min-w-full bg-gray-100 border border-gray-300 rounded-lg">
//               <thead>
//                 <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
//                   <th className="py-3 px-6 text-left">Column 1</th>
//                   <th className="py-3 px-6 text-left">Column 2</th>
//                   <th className="py-3 px-6 text-left">Column 3</th>
//                   <th className="py-3 px-6 text-left">Column 4</th>
//                 </tr>
//               </thead>
//               <tbody className="text-gray-700 text-sm font-light">
//                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
//                   <td className="py-3 px-6 text-left">Data 1</td>
//                   <td className="py-3 px-6 text-left">Data 2</td>
//                   <td className="py-3 px-6 text-left">Data 3</td>
//                   <td className="py-3 px-6 text-left">Data 4</td>
//                 </tr>
//                 <tr className="border-b border-gray-300 hover:bg-gray-200 transition">
//                   <td className="py-3 px-6 text-left">Data 5</td>
//                   <td className="py-3 px-6 text-left">Data 6</td>
//                   <td className="py-3 px-6 text-left">Data 7</td>
//                   <td className="py-3 px-6 text-left">Data 8</td>
//                 </tr>
//                 {/* Add more rows as needed */}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ReportSystem;



import React, { useState, useEffect } from 'react';
import ppFrontImage1 from './ppfront.jpg'; // Ensure the path to your images is correct
import ppFrontImage2 from './ppfront.jpg';
import ppFrontImage3 from './ppfront.jpg';
import AssessmentTable from './AssessmentTable';

const images = [ppFrontImage1, ppFrontImage2, ppFrontImage3];

function ReportSystem() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Cycle through images
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3900); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-2 overflow-hidden">
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }

          .animate-marquee {
            animation: marquee 15s linear infinite;
            white-space: nowrap;
          }
        `}
      </style>

      {/* Glass Effect Full Width Card */}
      <div className="w-full backdrop-blur-xl bg-white bg-opacity-30 rounded-xl shadow-lg p-8 space-y-16 overflow-hidden">
        
        {/* Image Carousel Section */}
        <div className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-700 hover:scale-105">
          <img
            src={images[currentImageIndex]}
            alt="Government Report"
            className="w-full h-[24rem] object-cover transition-all duration-500 ease-in-out transform hover:scale-110"
          />
          <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
            {/* <h1 className="text-5xl font-bold text-white text-center">Prosperity Report System</h1> */}
          </div>

          {/* Moving Text at Bottom of the Image */}
          <div className="absolute bottom-0 w-full bg-blue-800 bg-opacity-70 text-white py-2 overflow-hidden">
            <div className="animate-marquee inline-block px-4">
              Welcome to the Prosperiy Report System - Secure and Efficient
            </div>
          </div>
        </div>

        {/* Action Button */}
     
        {/* Table Section with Custom Colors and Effects */}
        <div className="bg-white bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg p-1 max-h-96 overflow-y-scroll">
          {/* <h2 className="text-2xl font-semibold mb-4 text-gray-800">Summary Report Graphs</h2> */}
          <AssessmentTable />
        </div>
      </div>
    </div>
  );
}

export default ReportSystem;