// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';  // Import axios for making API calls
// // // import securelocalStorage from 'secure-localstorage';  // Correct import for secure-localstorage
// // import securelocalStorage from 'react-secure-storage';

// // const AssessmentTable = () => {
// //     // State to hold the form data
// //     const [formData, setFormData] = useState({
// //         fullName: '',
// //         district: '',
// //         institution: '',
// //         responsibility: '',
// //         user: '',
// //         partyFollower: '',
// //         prohibitionOfSelling: '',
// //         approval: '',
// //     });

// //     // State to hold the list of assessments fetched from API
// //     const [assessments, setAssessments] = useState([]);

// //     // State for search and filter
// //     const [searchTerm, setSearchTerm] = useState('');
// //     const [filterColor, setFilterColor] = useState('');

// //     // Fetch assessments on component mount
// //     useEffect(() => {
// //         // Get the token from secure local storage
// //         const token = securelocalStorage.getItem('token');  // Adjust the key if needed

// //         // Make API request with token in headers
// //         axios.get('https://api.eth-prosperity.com/api/assessments/', {
// //             headers: {
// //                 Authorization: `Token ${securelocalStorage.getItem('token')}`, // Adjust this if using securelocalStorage
// //               },
            
// //         })
// //             .then(response => {
// //                 setAssessments(response.data); 
// //                  // Set the fetched assessments into state
// //             })
// //             .catch(error => {
// //                 console.error("There was an error fetching the assessments:", error);
// //                 console.log(token)
// //             });
// //     }, []);

// //     // Handle input field change
// //     const handleChange = (e) => {
// //         const { name, value } = e.target;
// //         setFormData({
// //             ...formData,
// //             [name]: value,
// //         });
// //     };

// //     // Handle form submission and make a POST request to add new assessment
// //     const handleSubmit = (e) => {
// //         e.preventDefault();

// //         // Get the token from secure local storage
// //         // const token = securelocalStorage.getItem('token');  // Adjust the key if needed
// //         const token = securelocalStorage.getItem('token');
// //         // Make POST request with token in headers
// //         console.log('token'===token); 
// //         console.log('token'===token);
// //         console.log('token'===token);
// //         console.log('token'===token);
// //         console.log('token'===token); // Log the token for debugging
       
// //         console.log('Retrieved Token:', token); // Check if the token is null or undefined

// //         axios.post('https://api.eth-prosperity.com/api/assessments/', formData, {
// //             headers: {
// //                 Authorization: `Token ${securelocalStorage.getItem('token')}`,
// //             }
            
// //         })
// //             .then(response => {
// //                 // After successful POST, fetch updated list
// //                 setAssessments([...assessments, response.data]);
// //                 setFormData({
// //                     fullName: '',
// //                     district: '',
// //                     institution: '',
// //                     responsibility: '',
// //                     user: '',
// //                     partyFollower: '',
// //                     prohibitionOfSelling: '',
// //                     approval: '',
// //                 });
// //             })
// //             .catch(error => {
// //                 console.error("There was an error adding the assessment:", error);
// //             });
// //     };

// //     // Function to determine the background color class based on selection
// //     const getColorClass = (value) => {
// //         switch (value) {
// //             case 'green':
// //                 return 'bg-green-200';
// //             case 'yellow':
// //                 return 'bg-yellow-200';
// //             case 'red':
// //                 return 'bg-red-200';
// //             default:
// //                 return '';
// //         }
// //     };

// //     // Filtered assessments based on search term and color filter
// //     const filteredAssessments = assessments.filter(assessment => {
// //         const matchesSearchTerm = Object.values(assessment).some(value =>
// //             value.toLowerCase().includes(searchTerm.toLowerCase())
// //         );

// //         const matchesColor = filterColor
// //             ? [assessment.user, assessment.partyFollower, assessment.prohibitionOfSelling, assessment.approval].includes(filterColor)
// //             : true;

// //         return matchesSearchTerm && matchesColor;
// //     });

// //     return (
// //         <div className="container mx-auto px-4 py-8">
// //             <h2 className="text-2xl font-bold mb-4">Higher Level Assessment Table</h2>

// //             {/* Form for new assessment entry */}
// //             <form onSubmit={handleSubmit} className="mb-6">
// //                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">ሙሉ ስም (Full Name)</label>
// //                         <input
// //                             type="text"
// //                             name="fullName"
// //                             value={formData.fullName}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         />
// //                     </div>
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">የሚገኙበት ወረዳ (District)</label>
// //                         <input
// //                             type="text"
// //                             name="district"
// //                             value={formData.district}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         />
// //                     </div>
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">የሚሰሩበት ተቋም (Institution)</label>
// //                         <input
// //                             type="text"
// //                             name="institution"
// //                             value={formData.institution}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         />
// //                     </div>
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">በተቋሙ ያላቸው የሀላፊነት (Responsibility)</label>
// //                         <input
// //                             type="text"
// //                             name="responsibility"
// //                             value={formData.responsibility}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         />
// //                     </div>

// //                     {/* Color Selection Fields */}
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">ተጠቃሚ (User)</label>
// //                         <select
// //                             name="user"
// //                             value={formData.user}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         >
// //                             <option value="">Select Color</option>
// //                             <option value="green">Green</option>
// //                             <option value="yellow">Yellow</option>
// //                             <option value="red">Red</option>
// //                         </select>
// //                     </div>
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">የጠ/ር ተከታይ (Party Follower)</label>
// //                         <select
// //                             name="partyFollower"
// //                             value={formData.partyFollower}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         >
// //                             <option value="">Select Color</option>
// //                             <option value="green">Green</option>
// //                             <option value="yellow">Yellow</option>
// //                             <option value="red">Red</option>
// //                         </select>
// //                     </div>
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">ሀሳብ መሸጥ (Prohibition of Selling)</label>
// //                         <select
// //                             name="prohibitionOfSelling"
// //                             value={formData.prohibitionOfSelling}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         >
// //                             <option value="">Select Color</option>
// //                             <option value="green">Green</option>
// //                             <option value="yellow">Yellow</option>
// //                             <option value="red">Red</option>
// //                         </select>
// //                     </div>
// //                     <div>
// //                         <label className="block text-sm font-semibold text-gray-700">የሚከላከሉ (Approval)</label>
// //                         <select
// //                             name="approval"
// //                             value={formData.approval}
// //                             onChange={handleChange}
// //                             required
// //                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
// //                         >
// //                             <option value="">Select Color</option>
// //                             <option value="green">Green</option>
// //                             <option value="yellow">Yellow</option>
// //                             <option value="red">Red</option>
// //                         </select>
// //                     </div>
// //                 </div>
// //                 <button
// //                     type="submit"
// //                     className="mt-4 p-2 bg-blue-500 text-white rounded-lg"
// //                 >
// //                     Submit
// //                 </button>
// //             </form>

// //             {/* Search and Filter Section */}
// //             <div className="mb-6">
// //                 <input
// //                     type="text"
// //                     placeholder="Search"
// //                     value={searchTerm}
// //                     onChange={(e) => setSearchTerm(e.target.value)}
// //                     className="p-2 border border-gray-300 rounded-lg w-1/2"
// //                 />
// //                 <select
// //                     onChange={(e) => setFilterColor(e.target.value)}
// //                     className="ml-4 p-2 border border-gray-300 rounded-lg"
// //                 >
// //                     <option value="">Filter by Color</option>
// //                     <option value="green">Green</option>
// //                     <option value="yellow">Yellow</option>
// //                     <option value="red">Red</option>
// //                 </select>
// //             </div>

// //             {/* Assessment Table */}
// //             <table className="w-full table-auto border-collapse border border-gray-300">
// //                 <thead>
// //                     <tr>
// //                         <th className="px-4 py-2 border">Full Name</th>
// //                         <th className="px-4 py-2 border">District</th>
// //                         <th className="px-4 py-2 border">Institution</th>
// //                         <th className="px-4 py-2 border">Responsibility</th>
// //                         <th className="px-4 py-2 border">User</th>
// //                         <th className="px-4 py-2 border">Party Follower</th>
// //                         <th className="px-4 py-2 border">Prohibition of Selling</th>
// //                         <th className="px-4 py-2 border">Approval</th>
// //                     </tr>
// //                 </thead>
// //                 <tbody>
// //                     {filteredAssessments.map((assessment, index) => (
// //                         <tr key={index} className={getColorClass(assessment.user)}>
// //                             <td className="px-4 py-2 border">{assessment.fullName}</td>
// //                             <td className="px-4 py-2 border">{assessment.district}</td>
// //                             <td className="px-4 py-2 border">{assessment.institution}</td>
// //                             <td className="px-4 py-2 border">{assessment.responsibility}</td>
// //                             <td className="px-4 py-2 border">{assessment.user}</td>
// //                             <td className="px-4 py-2 border">{assessment.partyFollower}</td>
// //                             <td className="px-4 py-2 border">{assessment.prohibitionOfSelling}</td>
// //                             <td className="px-4 py-2 border">{assessment.approval}</td>
// //                         </tr>
// //                     ))}
// //                 </tbody>
// //             </table>
// //         </div>
// //     );
// // };

// // export default AssessmentTable;


// import React, { useState, useEffect } from 'react';
// import securelocalStorage from 'react-secure-storage';


// const AssessmentTable = () => {
//     // State to hold the form data
//     const [formData, setFormData] = useState({
//         fullName: '',
//         district: '',
//         institution: '',
//         responsibility: '',
//         user: '',
//         partyFollower: '',
//         prohibitionOfSelling: '',
//         approval: '',
//     });

//     // State to hold the list of assessments fetched from API
//     const [assessments, setAssessments] = useState([]);

//     // State for search and filter
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filterColor, setFilterColor] = useState('');

//     // Fetch assessments on component mount
//     useEffect(() => {
//         // const token = securelocalStorage.getItem('token');  // Adjust the key if needed
//         const token = securelocalStorage.getItem("token");
// console.log("Retrieved Token:", token); // Debugging
// if (!token) {
//     console.error("Token is missing or invalid!");
//     alert("You are not authorized. Please log in again.");
//     return;
// }


//         // Make API request with token in headers
//         fetch('https://api.eth-prosperity.com/api/assessments/', {
//             method: 'GET',
//             headers: {
//                 'Authorization': `Token ${token}`,
//             },
//         })
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }
//             return response.json();
//         })
//         .then(data => {
//             setAssessments(data); 
//         })
//         .catch(error => {
//             console.error("There was an error fetching the assessments:", error);
//         });
//     }, []);

//     // Handle input field change
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     // Handle form submission and make a POST request to add new assessment
//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const token = securelocalStorage.getItem('token');  // Adjust the key if needed
//         console.log("Retrieved Token:", token);

//         const postData = {
//             full_name: formData.fullName,
//             district: formData.district,
//             institution: formData.institution,
//             responsibility: formData.responsibility,
//             user: formData.user,
//             party_follower: formData.partyFollower,
//             prohibition_of_selling: formData.prohibitionOfSelling,
//             approval: formData.approval,
//         };

//         try {
//             const response = await fetch('https://api.eth-prosperity.com/api/assessments/', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Token ${token}`,
//                 },
//                 body: JSON.stringify(postData),
//             });

//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }

//             const data = await response.json();
//             setAssessments([...assessments, data]);
//             setFormData({
//                 fullName: '',
//                 district: '',
//                 institution: '',
//                 responsibility: '',
//                 user: '',
//                 partyFollower: '',
//                 prohibitionOfSelling: '',
//                 approval: '',
//             });
//         } catch (error) {
//             console.error("There was an error adding the assessment:", error);
//         }
//     };

//     // Function to determine the background color class based on selection
//     const getColorClass = (value) => {
//         switch (value) {
//             case 'green':
//                 return 'bg-green-200';
//             case 'yellow':
//                 return 'bg-yellow-200';
//             case 'red':
//                 return 'bg-red-200';
//             default:
//                 return '';
//         }
//     };

//     // Filtered assessments based on search term and color filter
//     const filteredAssessments = assessments.filter(assessment => {
//         const matchesSearchTerm = Object.values(assessment).some(value =>
//             value.toLowerCase().includes(searchTerm.toLowerCase())
//         );

//         const matchesColor = filterColor
//             ? [assessment.user, assessment.party_follower, assessment.prohibition_of_selling, assessment.approval].includes(filterColor)
//             : true;

//         return matchesSearchTerm && matchesColor;
//     });

//     return (
//         <div className="container mx-auto px-4 py-8">
//             <h2 className="text-2xl font-bold mb-4">Higher Level Assessment Table</h2>

//             {/* Form for new assessment entry */}
//             <form onSubmit={handleSubmit} className="mb-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">ሙሉ ስም (Full Name)</label>
//                         <input
//                             type="text"
//                             name="fullName"
//                             value={formData.fullName}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">የሚገኙበት ወረዳ (District)</label>
//                         <input
//                             type="text"
//                             name="district"
//                             value={formData.district}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">የሚሰሩበት ተቋም (Institution)</label>
//                         <input
//                             type="text"
//                             name="institution"
//                             value={formData.institution}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">በተቋሙ ያላቸው የሀላፊነት (Responsibility)</label>
//                         <input
//                             type="text"
//                             name="responsibility"
//                             value={formData.responsibility}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         />
//                     </div>

//                     {/* Color Selection Fields */}
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">ተጠቃሚ (User)</label>
//                         <select
//                             name="user"
//                             value={formData.user}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         >
//                             <option value="">Select Color</option>
//                             <option value="green">Green</option>
//                             <option value="yellow">Yellow</option>
//                             <option value="red">Red</option>
//                         </select>
//                     </div>
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">የጠ/ር ተከታይ (Party Follower)</label>
//                         <select
//                             name="partyFollower"
//                             value={formData.partyFollower}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         >
//                             <option value="">Select Color</option>
//                             <option value="green">Green</option>
//                             <option value="yellow">Yellow</option>
//                             <option value="red">Red</option>
//                         </select>
//                     </div>
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">ሀሳብ መሸጥ (Prohibition of Selling)</label>
//                         <select
//                             name="prohibitionOfSelling"
//                             value={formData.prohibitionOfSelling}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         >
//                             <option value="">Select Color</option>
//                             <option value="green">Green</option>
//                             <option value="yellow">Yellow</option>
//                             <option value="red">Red</option>
//                         </select>
//                     </div>
//                     <div>
//                         <label className="block text-sm font-semibold text-gray-700">የሚከላከሉ (Approval)</label>
//                         <select
//                             name="approval"
//                             value={formData.approval}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
//                         >
//                             <option value="">Select Color</option>
//                             <option value="green">Green</option>
//                             <option value="yellow">Yellow</option>
//                             <option value="red">Red</option>
//                         </select>
//                     </div>
//                 </div>
//                 <button
//                     type="submit"
//                     className="mt-4 p-2 bg-blue-500 text-white rounded-lg"
//                 >
//                     Submit
//                 </button>
//             </form>

//             {/* Search and Filter Section */}
//             <div className="mb-6">
//                 <input
//                     type="text"
//                     placeholder="Search"
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="p-2 border border-gray-300 rounded-lg w-1/2"
//                 />
//                 <select
//                     onChange={(e) => setFilterColor(e.target.value)}
//                     className="ml-4 p-2 border border-gray-300 rounded-lg"
//                 >
//                     <option value="">Filter by Color</option>
//                     <option value="green">Green</option>
//                     <option value="yellow">Yellow</option>
//                     <option value="red">Red</option>
//                 </select>
//             </div>

//             {/* Assessment Table */}
//             <table className="w-full table-auto border-collapse border border-gray-300">
//                 <thead>
//                     <tr>
//                         <th className="px-4 py-2 border">Full Name</th>
//                         <th className="px-4 py-2 border">District</th>
//                         <th className="px-4 py-2 border">Institution</th>
//                         <th className="px-4 py-2 border">Responsibility</th>
//                         <th className="px-4 py-2 border">User</th>
//                         <th className="px-4 py-2 border">Party Follower</th>
//                         <th className="px-4 py-2 border">Prohibition of Selling</th>
//                         <th className="px-4 py-2 border">Approval</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {filteredAssessments.map((assessment, index) => (
//                         <tr key={index} className={getColorClass(assessment.user)}>
//                             <td className="px-4 py-2 border">{assessment.full_name}</td>
//                             <td className="px-4 py-2 border">{assessment.district}</td>
//                             <td className="px-4 py-2 border">{assessment.institution}</td>
//                             <td className="px-4 py-2 border">{assessment.responsibility}</td>
//                             <td className={`px-4 py-2 border ${getColorClass(assessment.user)}`}>{assessment.user}</td>
//                             <td className={`px-4 py-2 border ${getColorClass(assessment.party_follower)}`}>{assessment.party_follower}</td>
//                             <td className={`px-4 py-2 border ${getColorClass(assessment.prohibition_of_selling)}`}>{assessment.prohibition_of_selling}</td>
//                             <td className={`px-4 py-2 border ${getColorClass(assessment.approval)}`}>{assessment.approval}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default AssessmentTable;


import React, { useState, useEffect } from 'react';
import securelocalStorage from 'react-secure-storage';

const AssessmentTable = () => {
    // State to hold the form data
    const [formData, setFormData] = useState({
        fullName: '',
        district: '',
        institution: '',
        responsibility: '',
        user: '',
        partyFollower: '',
        prohibitionOfSelling: '',
        approval: '',
    });

    // State to hold the list of assessments fetched from API
    const [assessments, setAssessments] = useState([]);

    // State for search and filter
    const [searchTerm, setSearchTerm] = useState('');
    const [filterColor, setFilterColor] = useState('');

    // Fetch assessments on component mount
    useEffect(() => {
        const token = securelocalStorage.getItem('token');  // Adjust the key if needed

        // Make API request with token in headers
        fetch('https://api.eth-prosperity.com/api/assessments/', {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            setAssessments(data); 
        })
        .catch(error => {
            console.error("There was an error fetching the assessments:", error);
        });
    }, []);

    // Handle input field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission and make a POST request to add new assessment
    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = securelocalStorage.getItem('token');  // Adjust the key if needed

        const postData = {
            full_name: formData.fullName,
            district: formData.district,
            institution: formData.institution,
            responsibility: formData.responsibility,
            user: formData.user,
            party_follower: formData.partyFollower,
            prohibition_of_selling: formData.prohibitionOfSelling,
            approval: formData.approval,
        };

        try {
            const response = await fetch('https://api.eth-prosperity.com/api/assessments/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setAssessments([...assessments, data]);
            setFormData({
                fullName: '',
                district: '',
                institution: '',
                responsibility: '',
                user: '',
                partyFollower: '',
                prohibitionOfSelling: '',
                approval: '',
            });
        } catch (error) {
            console.error("There was an error adding the assessment:", error);
        }
    };

    // Function to determine the background color class based on selection
    const getColorClass = (value) => {
        switch (value) {
            case 'green':
                return 'bg-green-200';
            case 'yellow':
                return 'bg-yellow-200';
            case 'red':
                return 'bg-red-200';
            default:
                return '';
        }
    };

    // Filtered assessments based on search term and color filter
    const filteredAssessments = assessments.filter(assessment => {
        const matchesSearchTerm = Object.values(assessment).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const matchesColor = filterColor
            ? [assessment.user, assessment.party_follower, assessment.prohibition_of_selling, assessment.approval].includes(filterColor)
            : true;

        return matchesSearchTerm && matchesColor;
    });

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl font-bold mb-4">የከፍተኛ አመራር መረጃ  </h2>

            {/* Form for new assessment entry */}
            <form onSubmit={handleSubmit} className="mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">ሙሉ ስም (Full Name)</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">የሚገኙበት ወረዳ (District)</label>
                        <input
                            type="text"
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">የሚሰሩበት ተቋም (Institution)</label>
                        <input
                            type="text"
                            name="institution"
                            value={formData.institution}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">በተቋሙ ያላቸው የሀላፊነት (Responsibility)</label>
                        <input
                            type="text"
                            name="responsibility"
                            value={formData.responsibility}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        />
                    </div>

                    {/* Color Selection Fields */}
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">ተጠቃሚ (User)</label>
                        <select
                            name="user"
                            value={formData.user}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        >
                            <option value="">Select Color</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                            <option value="red">Red</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">የጠ/ር ተከታይ (Party Follower)</label>
                        <select
                            name="partyFollower"
                            value={formData.partyFollower}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        >
                            <option value="">Select Color</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                            <option value="red">Red</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">ሀሳብ መሸጥ (Prohibition of Selling)</label>
                        <select
                            name="prohibitionOfSelling"
                            value={formData.prohibitionOfSelling}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        >
                            <option value="">Select Color</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                            <option value="red">Red</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">የሚከላከሉ (Defend)</label>
                        <select
                            name="approval"
                            value={formData.approval}
                            onChange={handleChange}
                            required
                            className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                        >
                            <option value="">Select Color</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                            <option value="red">Red</option>
                        </select>
                    </div>
                </div>
                <button
                    type="submit"
                    className="mt-4 p-2 bg-blue-500 text-white rounded-lg"
                >
                    Submit
                </button>
            </form>

            {/* Search and Filter Section */}
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border border-gray-300 rounded-lg w-1/2"
                />
                <select
                    onChange={(e) => setFilterColor(e.target.value)}
                    className="ml-4 p-2 border border-gray-300 rounded-lg"
                >
                    <option value="">Filter by Color</option>
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                </select>
            </div>

            {/* Assessment Table */}
            <table className="w-full table-auto border-collapse border border-gray-300">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border">ሙሉ ስም</th>
                        <th className="px-4 py-2 border">የሚገኙበት ወረዳ</th>
                        <th className="px-4 py-2 border">የሚሰሩበት ተቋም</th>
                        <th className="px-4 py-2 border">በተቋሙ ያላቸው የሀላፊነት</th>
                        <th className="px-4 py-2 border">ተጠቃሚ</th>
                        <th className="px-4 py-2 border">የጠ/ር ተከታይ </th>
                        <th className="px-4 py-2 border">ሀሳብ መሸጥ </th>
                        <th className="px-4 py-2 border">የሚከላከሉ</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAssessments.map((assessment, index) => (
                        <tr key={index} className={getColorClass(assessment.user)}>
                            <td className="px-4 py-2 border">{assessment.full_name}</td>
                            <td className="px-4 py-2 border">{assessment.district}</td>
                            <td className="px-4 py-2 border">{assessment.institution}</td>
                            <td className="px-4 py-2 border">{assessment.responsibility}</td>
                            <td className={`px-4 py-2 border ${getColorClass(assessment.user)}`}>{assessment.user}</td>
                            <td className={`px-4 py-2 border ${getColorClass(assessment.party_follower)}`}>{assessment.party_follower}</td>
                            <td className={`px-4 py-2 border ${getColorClass(assessment.prohibition_of_selling)}`}>{assessment.prohibition_of_selling}</td>
                            <td className={`px-4 py-2 border ${getColorClass(assessment.approval)}`}>{assessment.approval}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AssessmentTable;